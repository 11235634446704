import React from 'react'
import Helmet from 'react-helmet'

import {
  Layout,
  BannerSmall,
  BannerLinks,
  TextBox,
  BlogSlider,
  Breadcrumbs,
  Appointment
} from '../../components'

import cards from '../../components/BlogSlider/DefaultCards'

import BannerImage from '../../assets/header.seo.jpg'
import ExklusivesAngebot from '../../assets/bannerlinks/exklusivesangebot.jpg'
import CalenderImage from '../../assets/bannerlinks/calendar.jpg'
import Georg from '../../assets/georg_neu.png'

import { generateBreadcrumbsLinks } from '../../utils/index'

const boxes = [
  {
    text: 'Exklusive Angebote',
    image: ExklusivesAngebot,
    link: '/kontakt',
    linkText: 'Rückruf anfordern'
  },
  {
    text: 'Jetzt Termin vereinbaren',
    image: CalenderImage,
    link: '/kontakt',
    linkText: 'Online Kalender'
  }
]

const Seo = () => {
  const links = generateBreadcrumbsLinks(
    '/blog/mittels-seo-zur-besten-platzierung',
    'Mittels SEO zur besseren Platzierung'
  )
  return (
    <Layout>
      <Helmet
        title="Mit SEO zur Top-Platzierung Ihrer Website"
        meta={[
          {
            name: 'description',
            content:
              'Wie schaffe ich mit meiner Unternehmens-Website eine Top-Platzierung in den Ergebnislisten der Suchmaschinen? Wie locke ich durch die bestmögliche Präsenz möglichst viele potentielle Kund:innen auf meine Seite?'
          },
          {
            name: 'keywords',
            content:
              'SEO holzweg, Blog holzweg, Innsbruck, holzweg, Blog, SEO, Suchmaschinenoptimierung holzweg, Onpage, Offpage'
          },
          {
            name: 'image',
            content:
              'https://www.holzweg.com/static/header.seo-037456c56a399bcd6a510eb9659f263f.jpg'
          },
          {
            property: 'og:description',
            content:
              'Wie schaffe ich mit meiner Unternehmens-Website eine Top-Platzierung in den Ergebnislisten der Suchmaschinen? Wie locke ich durch die bestmögliche Präsenz möglichst viele potentielle Kund:innen auf meine Seite?'
          },
          {
            property: 'og:image',
            content:
              'https://www.holzweg.com/static/header.seo-037456c56a399bcd6a510eb9659f263f.jpg'
          }
        ]}
      />
      <BannerSmall
        image={BannerImage}
        title={<span>Sportlicher Wettkampf</span>}
      />
      <Breadcrumbs backLinkAlias=" " links={links} />
      <TextBox
        title="Mit SEO zur Top-Platzierung Ihrer Website"
        subTitle="Innsbruck, am 10. Oktober 2018"
        text={
          <span>
            <p>
              <b>
                Wie schaffe ich mit meiner Unternehmens-Website eine
                Top-Platzierung in den Ergebnislisten der Suchmaschinen? Wie
                locke ich durch die bestmögliche Präsenz möglichst viele
                potentielle Kund:innen auf meine Seite?
              </b>
            </p>
            <p>
              Suchmaschinenoptimierung (SEO = Search Engine Optimization) ist
              das Schlagwort schlechthin. Mein Unternehmen muss im Internet
              gefunden werden! Je höher meine Positionierung in den Ergebnissen
              der Suchmaschinen ist, desto mehr Zugriffe erhält meine Webseite.
            </p>
            <p>
              Suchmaschinen erfassen Webseiten und untersuchen diese nach
              bestimmten Kriterien. Ziel sollte es also sein, die besten
              Kriterien zu liefern, um am besten gereiht und somit gefunden zu
              werden.
            </p>
            <p>
              SEO findet auf zwei Arten statt: mittels <b>Onpage-Optimierung</b>{' '}
              schafft man die technisch, inhaltlich und strukturell besten
              Voraussetzungen auf der eigenen Webpräsenz.{' '}
              <b>Offpage-Optimierungen</b> geben außerhalb des eigenen
              Internetauftritts den zusätzliche Kick, um eine gute Reihung zu
              erreichen. Je mehr fremde Webseiten auf die eigene Seite verweisen
              (Backlinks, Linkbuilding), desto wichtiger wird man von den
              Suchmaschinen bewertet, desto besser wird man gerankt.
            </p>
            <p>
              Durch das Verlinken auf Social Media Kanälen, in Themenblogs oder
              Artikelverzeichnissen, erreicht man die bestmögliche
              Aufmerksamkeit und steigert stetig das Ranking seiner Website.
              Starke und gut gewählte Keywords in suchmaschinenoptimierten
              Texten pushen die Platzierung. Allerdings dürfen diese weder zu
              allgemein noch zu spezifisch gewählt werden. Wer Schlüsselwörter
              verwendet, die oft gesucht werden, wird besser gefunden.
            </p>
            <p>
              Usability ist auch bei den SEO-Trends das Schlagwort schlechthin.
              Die Ladezeit der mobilen Website ist ein wichtiger Faktor beim
              Ranking. Wer sich für SEO interessiert, kommt nicht um den Begriff{' '}
              <b>Mobile-First-Index</b> herum: wer Wert auf ein gutes Ranking
              legt, sollte auch Wert auf eine responsive Website oder im besten
              Fall sogar auf eine komplett optimierte mobile Variante legen!
              Reine Desktop-Varianten werden zwar weiterhin beachtet, erfahren
              aber eindeutig Nachteile beim Ranking.
            </p>
          </span>
        }
      />
      <Appointment image={Georg} />
      <BlogSlider cards={cards} singleRow={true} />
      <TextBox
        title="Walk with us on the holzweg"
        text="Ob Sonnen-Spaziergang oder Power-Lauf, wer sich mit uns trifft, kann schon mal ins Schwitzen kommen. Lassen Sie sich zu Spitzenleistungen antreiben und vereinbaren Sie jetzt Ihren Termin mit unseren Experten!"
      />
      <BannerLinks boxes={boxes} />
    </Layout>
  )
}

export default Seo
